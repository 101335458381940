<template>
  <div class="qr-popup">
    <div class="qr-popup__wrap">
      <div class="qr-popup__container">
        <div class="qr-popup__code">
          <qr-code :size="150" :text="qrCode"/>
        </div>
        <template v-if="!visibleOnlyQR">
          <div class="qr-popup__label">
            {{ $t('loginByQRCodeMessage') }}
          </div>
          <div class="qr-popup__footer">
            <MobileAppLinks />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import MobileAppLinks from '@/components/atoms/MobileAppLinks.vue'

const COUNTDOWN_TIME = 90
let timerTicker = null
let IntervalLongFunction = null
let IntervalShortFunction = null

export default {
  name: 'LoginByQRCode',
  props: {
    visibleOnlyQR: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fingerPrint: '',
      qrCode: '',
      iteration: 1,
      isGenerateNewQRCode: false
    }
  },
  components: {
    MobileAppLinks
  },
  created () {
    this.openPopup()
  },
  computed: {
    ...mapGetters({
      qrCodeLoginPopup: 'auth/qrCodeLoginPopup'
    })
  },
  methods: {
    ...mapActions({
      getSocialInfo: 'auth/getSocialInfo',
      authByCode: 'auth/authByCode',
      getPackages: 'auth/getPackages',
      getProfile: 'auth/getMyProfile'
    }),
    ...mapMutations({
      changeLoginByQRPopup: 'auth/changeLoginByQRPopup',
      changeOffersStep: 'offers/changeStep',
      changeUserStep: 'user/changeStep'
    }),
    openPopup () {
      this.iteration = 1
      this.isGenerateNewQRCode = false
      this.setNewQRCode()
      this.generateFingerPrint()
      this.startIterations()
      this.startProcessRequests()
      this.startTimer()
    },
    startTimer () {
      let minutes = Math.floor(COUNTDOWN_TIME / 60)
      let seconds = COUNTDOWN_TIME % 60
      let currentVal = COUNTDOWN_TIME
      timerTicker = setInterval(() => {
        if (!currentVal) {
          clearInterval(timerTicker)
          return
        }
        currentVal--
        seconds = currentVal % 60
        minutes = Math.floor(currentVal / 60)
        if (seconds === 0 && minutes === 0) {
          this.$modal.hide(this.$options.name)
        }
      }, 1000)
    },
    generateFingerPrint () {
      const fpPromise = FingerprintJS.load()
      fpPromise.then(fp => fp.get().then(result => {
        this.fingerPrint = 'holanew_' + result.visitorId
      }))
    },
    startProcessRequests () {
      IntervalLongFunction = setInterval(() => {
        this.startIterations()
      }, 10000)
    },
    startIterations () {
      IntervalShortFunction = setInterval(() => {
        this.startSendingRequests()
      }, 1000)
    },
    startSendingRequests () {
      if (this.iteration === 1 && !this.isGenerateNewQRCode) {
        this.sendRequestAction(this.qrCode)
        this.iteration = this.iteration + 1
      } else if (this.iteration === 1 && this.isGenerateNewQRCode) {
        this.sendRequestAction('')
        this.iteration = this.iteration + 1
      } else if (this.iteration > 1 && this.iteration < 10) {
        this.sendRequestAction('')
        this.iteration = this.iteration + 1
      } else if (this.iteration === 10) {
        clearInterval(IntervalShortFunction)
        this.setNewQRCode()
        this.sendRequestAction(this.qrCode)
        this.iteration = 1
        this.isGenerateNewQRCode = true
      }
    },
    sendRequestAction (qrCode) {
      if (this.fingerPrint) {
        this.authByCode({
          data: {
            device: [{
              id: '73617948-6f6c-6120-426c-6f6720777000',
              type: 'web'
            }],
            qr_code: qrCode
          },
          fingerprint: this.fingerPrint
        }).then(response => {
          if (response.data && Object.keys(response.data).length === 0 && Object.getPrototypeOf(response.data) === Object.prototype) {} else {
            setTimeout(() => {
              this.redirectAfterLoginByQRCode()
            }, 400)
          }
        })
      }
    },
    generateRandomQRCode (length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    setNewQRCode () {
      this.qrCode = 'holanew_' + this.generateRandomQRCode(37)
    },
    redirectAfterLoginByQRCode () {
      console.log(this.qrCodeLoginPopup)
      if (this.qrCodeLoginPopup.type === 'offer') {
        this.getProfile()
        this.changeOffersStep(2)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else if (this.qrCodeLoginPopup.type === 'bridge') {
        this.getProfile()
        this.$emit('on-success-auth', 2)
      } else {
        this.getPackages()
        this.getProfile()
        this.changeUserStep(2)
      }
      this.changeLoginByQRPopup({ show: false, type: '' })
    },
    beforeClose () {
      clearInterval(IntervalLongFunction)
      clearInterval(IntervalShortFunction)
      this.fingerPrint = ''
      this.qrCode = ''
      this.iteration = 1
      this.isGenerateNewQRCode = false
      clearInterval(timerTicker)
    },
    resetIntervals () {
      clearInterval(IntervalLongFunction)
      clearInterval(IntervalShortFunction)
      clearInterval(timerTicker)
    }
  },
  destroyed () {
    this.beforeClose()
  }
}

</script>
<style lang="scss">
.qr-popup{
  background: $white;
  @include respondTo(500px){
    margin: 0 auto;
    padding: 20px 0 30px;
  }
  &__wrap {
    position: relative;
    overflow: hidden;
    background-color: white;
    padding: 36px 32px;
    text-align: center;
    @include respondTo(500px){
      padding: 36px 20px;
    }
  }
  &__label{
    padding: 0 15px;
    text-align: center;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    margin: 10px auto;
    max-width: 360px;
    user-select: none;
    @include respondTo(600px){
      font-size: 14px;
    }
  }
  &__code{
    img {
      user-select: none;
      margin: 0 auto;
      padding: 15px 0;
    }
  }
}
</style>
